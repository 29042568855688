.footerContainer {
    color: #FFFFFF;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 60px;

    & hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    margin-bottom: 70px;
    /* margin-top: 70px; */
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Poopins-Bold";
    font-size: 32px;
}

.about {
    display: flex;
    gap: 30px;
    font-family: "Poopins-Regular";
    font-size: 15px;
    /* font-weight: 500; */
    /* line-height: 15px; */
    letter-spacing: 0em;
    text-align: left;

}

.socialMedia {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 40px;
    width: 50%;

    & img {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
}

@media screen and (max-width:769px) {
    .footer{
        font-size: 24px !important;
    }
    .socialMedia {
       
        gap: 20px;
        width: 50%;
    
       
    }
    .about {
       
        gap: 15px;
    }
    
}

a{
    text-decoration: none;
    color: #FFFFFF;
}