h1,
p,
div {
    text-wrap: wrap;
    word-wrap: break-word;
}

.mainContainer {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.heading {
    padding: 50px;
    border-radius: 30px;
    width: 1020px;
    max-width: 100%;
    font-family: Poopins-Bold;
    font-size: 60px;
    margin-bottom: 60px;
    color: var(--white-text);
}

.heading h1 {
    font-family: Poopins-Bold;
    font-size: 60px;

}

.heading p {
    font-size: 18px;
    font-family: "Poopins-medium" !important;
    color: var(--white-text);
}

.gradient {
    background: linear-gradient(95.01deg, #8226F7 2.42%, #FE655D 81.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contentImgContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    // font-size: 100px;
    color: #FFFFFF;
    gap: 20px;
    width: 800px;
    max-width: 100%;


    & button {
        // text-decoration: none;
        // border: none;
        // border-radius: 2px;
        // font-family: "Poopins-SemiBold";
        // color: #000000;
        // font-size: 16px;
        // padding: 6px 12px;
        // max-width: fit-content;
        // margin-bottom: 50px;
        padding: 10px 20px 10px 20px;
        font-family: Poopins-SemiBold;
        font-size: 20px;
        // font-weight: 700;
        line-height: 24px;
        background-color: #FFFFFF;
        color: #000000;
        border-radius: 5px;
        border: none;
        margin-top: 20px;
        width: fit-content;
        max-width: 100%;
    }

    & h1 {
        font-size: 60px;
        font-family: Poopins-Bold;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 100px;
    }

    & p {
        font-size: 20px;
        line-height: 29.26px !important;
        // margin-top: 50px;
        // margin-bottom: 50px;
        font-family: "Poopins-Regular";
    }
}

.retailIsHere {
    background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content {
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: flex-start;
}

.column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
}

image {
    max-width: 100%;
}

.main {
    margin: auto;
    width: 100%;
    max-width: 1300px;

    & .leadingCouriersHeading {
        padding: 50px;
        background-color: #1C1C1C;
        border-radius: 30px;
        max-width: 920px;
        margin: auto;
        color: var(--white-text);
        margin-top: 120px;

        & h1 {
            margin: 0px;
            padding: 0px;
            text-align: center;
        }

        & p {
            margin-top: 50px;
            font-family: Poopins-Regular;
            font-size: 20px;
            text-align: center;
        }

    }

    & h1 {
        margin: auto;
        margin-top: var(--mt-300);
        max-width: 1020px;
        font-family: Poopins-Bold;
        font-size: 60px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;
        padding: var(--pd-300);
        border-radius: 30px;
        margin-bottom: 50px;
    }

    & div {
        width: 100%;

        & h1 {
            margin: auto;
            margin-top: var(--mt-300);
            max-width: 1020px;
            font-family: Poopins-Bold;
            font-size: 60px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0em;
        }
    }
}

.getControl {
    & div {
        margin: auto;
        gap: 20px;
        // margin-bottom: 50px;

        & .getControlItems {
            width: 400px;
            padding: var(--pd-300);
            border-radius: 20px;
            color: var(--white-text);
            margin: 0px;
            max-width: 100%;

            & h2 {
                font-family: Poopins-Bold;
                margin-top: var(--mt-100);
            }

            & p {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                // line-height: 32px;
                letter-spacing: 0em;
                margin-top: var(--mt-100);
            }
        }

        & .getControlItems:nth-child(1) {
            background-color: var(--purple-dark-bg);
        }

        .getControlItems:nth-child(2) {
            background-color: #C046AA;
        }

        .getControlItems:nth-child(3) {
            background-color: #FE655D;
        }

        & .courierItems {
            position: relative;
            padding: var(--pd-200);
            max-width: 100%;
            width: 500px;
            color: var(--white-text);
            border-radius: 20px;
            height: 500px;
            margin: 0px;

            & span {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
            }

            & h2 {
                font-family: Poopins-Semibold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 20px;
            }
        }

        & .courierItems:nth-child(1),
        .courierItems:nth-child(4) {
            background-color: var(--purple-dark-bg);
        }

        & .courierItems:nth-child(2),
        .courierItems:nth-child(3) {
            background-color: #FE655D;
        }
    }

    & .shippingHeading {
        max-width: 900px;
        padding: var(--pd-300);
        border-radius: 30px;
        margin-bottom: 20px;
        background-color: #181818;
        margin-top: 60px;
        margin-bottom: 60px;

        & h1 {
            padding: 0px;
            margin-top: 0px;
        }

        & p {
            color: var(--white-text);
        }
    }

}

.reachCustomers {
    & div {
        margin: auto;
        gap: 20px;
        margin-top: 30px;


        & .reachCustomersItem {
            padding: var(--pd-200);
            max-width: 100%;
            width: 450px;
            color: var(--white-text);
            border-radius: 20px;
            margin: 0px;

            & img {
                width: 100px;
            }

            & span {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
            }

            & h2 {
                font-family: Poopins-Semibold;
                font-size: 40px;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }

        & .reachCustomersItem:nth-child(1),
        .reachCustomersItem:nth-child(4) {
            background-color: var(--pink-dark-bg);
        }

        & .reachCustomersItem:nth-child(2),
        .reachCustomersItem:nth-child(3) {
            background-color: #8226F7;
        }
    }
}

.leadingCouriers {
    text-align: center;
    max-width: 100%;
    margin-bottom: 50px;


    & button {
        padding: 10px 20px 10px 20px;
        font-family: Poopins-SemiBold;
        font-size: 20px;
        // font-weight: 700;
        line-height: 24px;
        background-color: #FFFFFF;
        color: #000000;
        border-radius: 5px;
        border: none;
        margin-top: 20px;
        width: fit-content;
        max-width: 100%;
    }

}

@media screen and (max-width:769px) {

    h1 {
        font-size: 34px !important;
        line-height: normal !important;
    }

    p {
        font-size: 18px !important;
        line-height: normal !important;
    }

    h2 {
        font-size: 28px !important;
        line-height: normal !important;
    }

}


@media screen and (max-width:769px) {
    .contentImgContainer {
        gap: 10px !important;
        // border: 2px solid red;

        // & h1,p{
        //     text-align: center !important;
        // }

        & button {
            width: 100%;
            margin-bottom: 60px;
        }
    }

    .heading {
        padding: 30px !important;
        border-radius: 30px;
        margin-bottom: 30px;
    }

    .main {
        & .leadingCouriersHeading {
            padding: 30px;
            margin-top: 80px;
            text-align: center;

            & p {
                margin-top: 30px;
                text-align: center !important;

            }
        }

        & h1 {

            // letter-spacing: 0em;
            // padding: var(--pd-300);
            // padding: 15px !important;
            border-radius: 30px;
            margin-bottom: 20px !important;

        }
    }

    .leadingCouriers {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.planet14 {
    position: absolute;
    left: 5%;
    top: 3%;
}

.planet15 {
    position: absolute;
    right: 5%;
    top: 3%;
}

.planet16 {
    position: absolute;
    right: 5%;
    top: 35%;
}

.planet17 {
    position: absolute;
    left: 5%;
    top: 45%;
}

.planet18 {
    position: absolute;
    left: 5%;
    top: 65%;
}

.planet19 {
    position: absolute;
    right: 5%;
    top: 65%;
}

.planet20 {
    position: absolute;
    right: 5%;
    top: 85%;
}

.planet21 {
    position: absolute;
    left: 5%;
    top: 85%;
}

.planet22 {
    position: absolute;
    left: 5%;
    top: 90%;
}

.planet23 {
    position: absolute;
    right: 5%;
    top: 97%;
}

@media screen and(max-width:769px) {
    .planet14 {
        position: absolute;
        left: 1%;
        top: 2%;
        width: 20%;
        z-index: -20;
        display: none;
    }

    .planet15 {
        position: absolute;
        right: 5%;
        top: 4%;
        z-index: -20;
        width: 20%;
        display: none;
    }

    .planet16 {
        position: absolute;
        right: -12%;
        top: 20%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet17 {
        position: absolute;
        left: 0%;
        top: 43%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet18 {
        position: absolute;
        left: -5%;
        top: 61%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet19 {
        position: absolute;
        right: 0%;
        top: 72%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet20 {
        position: absolute;
        right: 0%;
        top: 82%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet21 {
        position: absolute;
        left: 2%;
        top: 87%;
        z-index: -20;
        width: 30%;
        display: none;
    }

    .planet22 {
        position: absolute;
        left: 2%;
        top: 92%;
        z-index: -20;
        width: 40%;
        display: none;
    }

    .planet23 {
        position: absolute;
        right: 5%;
        top: 99%;
        z-index: -20;
        width: 40%;
        display: none;
    }

    .courierItems:nth-child(3) {
        order: 4;
    }

    .reachCustomersItem:nth-child(3){
        order: 4;
    }
}

@media screen and (min-width:992px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:992px) {}

@media screen and (max-width:600px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:996px) {}


@media screen and (min-width:1400px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}


@media screen and (min-width:996px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}