h1,
p,
div {
    text-wrap: wrap;
    word-wrap: break-word;
}

.mainContainer {
    width: 100%;
    // background-image: url(../../../public/assets/images/sass-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5%;
    // padding-left: 10px;
    // padding-right: 10px;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.contentImgContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 800px;
    // width: 55%;
    max-width: 100%;
    // font-size: 100px;
    // line-height: 121.9px;
    color: #FFFFFF;
    // border: 2px solid red;
    gap: 20px;
    margin-top: 190px;
}

.getStartedBtn {
    padding: 10px 20px 10px 20px;
    font-family: Poopins-SemiBold;
    font-size: 20px;
    // font-weight: 700;
    line-height: 24px;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 5px;
    border: none;
    // margin-top: 20px;
    width: fit-content;
    max-width: 100%;
    text-decoration: none;

}

.contentImgContainer h1 {
    font-size: 60px;
    font-family: "Poopins-Bold";
    padding: 0px !important;
    margin: 0px !important;
    // border: 2px solid green;
    /* font-weight: 700; */
    // line-height: 1.3;
    // letter-spacing: 0em;
    // text-align: left;
}

.contentImgContainer p {
    font-size: 20px;
    font-family: "Poopins-Regular";
    padding-bottom: 0px;
    margin: 0px;
    // margin-bottom: 10px;
    // line-height: 29.26px !important;
}

.retailIsHere {
    background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0px !important;
    margin: 0px !important;
}

.italic {
    font-family: "Poopins-Italic";
    font-size: 20px;

}

.content {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
}



.easilyManageDiv {
    background-color: #1C1C1C;
    border-radius: 12px;
    width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 40px 60px;
    margin-top: 220px;
    margin-bottom: 50px;
    max-width: 100%;
    // padding: 60px;
    // justify-content: center;

    & h1 {
        font-size: 60px;
        font-family: "Poopins-Bold";
        padding: 0px !important;
        margin: 0px !important;
        color: #8F3CF8;



    }

    & .highlight {
        color: #000000;
    }

    & p {
        font-family: "Poopins-Medium";
        font-size: 20px;

    }
}

.column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 1100px;
    max-width: 100%;
    gap: 10px;
}

.easilyManageContainer {
    // background-color: white;
    width: 1300px;
    max-width: 100%;
    display: flex;
    justify-content: center;

    .noBsItems {
        width: 1100px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 100%;



        & .noBsItem1 {
            // margin-top: 120px;
            background-color: #0D0D0D;
            color: #FFFFFF;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-radius: 20px;
            padding: 40px;
            gap: 40px;
            // margin-bottom: 20px;
            width: 100%;

            // border: 2px solid red;

            & .noBscontent {
                display: flex;
                flex-direction: column;
                gap: 15px;
                // border: 2px solid red;
                width: 450px;
                max-width: 100%;

                & h2 {
                    font-family: "Poopins-Bold";
                    font-size: 40px;
                    // font-weight: 700;
                    // line-height: 73px;
                    // letter-spacing: 0em;
                    text-align: left;
                    // border: 2px solid yellow;
                }

                & .noBsDesc {
                    width: 100% !important;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    // border: 2px solid green;

                    & p {
                        text-align: left;
                        font-family: "Poopins-Regular";
                        font-size: 16px;
                        // font-weight: 500;
                        // line-height: 22px;
                        // letter-spacing: 0em;
                        // text-align: left;
                        width: 100%;

                    }
                }


            }

            & img {
                width: 500px;
                max-width: 100%;
                object-fit: contain;
            }
        }

        & .noBsItem2 {
            background-color: #8F3CF8;
            color: #FFFFFF;
            border-radius: 20px;
            padding: 40px;
            display: flex;
            flex-direction: column;
            width: 540px;
            max-width: 100%;
            gap: 15px;
            // width: 600px;

            & h2 {
                font-family: Poopins-Bold;
                font-size: 40px;
                // font-weight: 700;
                // line-height: 73px;
                // letter-spacing: 0em;
                text-align: left;
            }

            & p {
                text-align: left;
                font-family: Poopins-Regular;
                font-size: 16px;
                color: #FFFFFF;
                // font-weight: 500;
                // line-height: 22px;
                // letter-spacing: 0em;
                // text-align: left;

            }

            & img {
                width: 80%;
                align-self: center;
                // border: 2px solid red;
                object-fit: contain;
                margin-top: 20px;
            }
        }
    }
}

.actionMainContainer {

    width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 50px;
    max-width: 100%;
    position: relative;

    // padding: 60px;
    // justify-content: center;
    & .actionContainer {
        background-color: #0D0D0D;
        border-radius: 12px;
        width: 1000px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 40px 60px;
        max-width: 100%;
        margin-bottom: 50px;
        // padding: 60px;
        // justify-content: center;

        & h1 {
            font-size: 60px;
            font-family: "Poopins-Bold";
            padding: 0px !important;
            margin: 0px !important;
            color: #222222;



        }

        & .highlight {
            color: #FF725E;
        }

        & p {
            font-family: "Poopins-Medium";
            font-size: 18px;
            color: #FFFFFF;
            // border: 2px solid red;

        }

    }

}


.actionCard {
    background-color: #8226F7;
    color: #FFFFFF;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    width: 540px;
    gap: 15px;
    max-width: 100%;
    // width: 600px;

    & h2 {
        font-family: Poopins-Bold;
        font-size: 40px;
        // font-weight: 700;
        // line-height: 73px;
        // letter-spacing: 0em;
        text-align: left;
    }

    & p {
        text-align: left;
        font-family: Poopins-Regular;
        font-size: 16px;
        // font-weight: 500;
        // line-height: 22px;
        // letter-spacing: 0em;
        // text-align: left;

    }

    & img {
        width: 60%;
        align-self: center;
        // border: 2px solid red;
        object-fit: contain;
    }
}

.levelUpContainer {
    // background-color:  --black-bg;
    background-color: "#1C1C1C";
    border-radius: 12px;
    width: 800px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 40px 60px;
    margin-top: 150px;
    margin-bottom: 50px;

    & h3 {
        font-family: Poopins-Bold;
        font-size: 40px;
        // font-weight: 700;
        // line-height: 73px;
        // letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 20px;
    }

    & .highlight {
        color: #A1A1A1;
    }

    & p {
        font-family: "Poopins-Regular";
        color: #FFFFFF;
        font-size: 22px;
    }

}

.usespaces {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 800px;
    max-width: 100%;
    border-radius: 12px !important;
    background: linear-gradient(#1C1C1C,#1C1C1C) padding-box,
    linear-gradient(to right, #7123d6, #f26159) border-box;
    // border-radius: 50em;
    border: 2px solid transparent;
    padding: 40px 60px;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px;
    margin-top: 50px;

    & h1 {
        font-family: Poopins-Bold;
        font-size: 40px;
        // font-weight: 700;
        // line-height: 73px;
        // letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    & button {
        padding: 10px 20px 10px 20px;
        font-family: Poopins-SemiBold;
        font-size: 20px;
        // font-weight: 700;
        line-height: 24px;
        background-color: #FFFFFF;
        color: #000000;
        border-radius: 5px;
        border: none;
        margin-top: 20px;
        width: fit-content;
        max-width: 100%;
    }

}

.useHyperspace {
    display: flex;
    gap: 20px;
    width: 1040px;
    margin: auto;
    position: relative;
    flex-wrap: wrap;

    & div {
        margin: 0px;
        background-color: #FFFFFF;
        padding: 50px;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 500px;
        max-width: 100%;

        & h1 {
            font-family: Poopins-Bold;
            font-size: 60px;
            text-align: left;
            margin-bottom: 0px;
        }

        & img {
            // position: absolute;
            // bottom: 50px;
            // left: 50px;
            width: 66px;
        }
    }

    & img {
        width: 500px;
    }
}

@media screen and (max-width:769px) {

    h1 {
        font-size: 34px !important;
        line-height: normal !important;
    }

    p {
        font-size: 18px !important;
        line-height: normal !important;
    }

    h2 {
        font-size: 28px !important;
        line-height: normal !important;
    }

}

@media screen and (max-width:769px) {
    .contentImgContainer {
        margin-top: 180px !important;
    }

    // .contentImgContainer h1{
    //     text-align: center;
    // }

    // .contentImgContainer p{
    //     text-align: center !important;
    // }

    .contentImgContainer button {
        width: 100%;
    }

    .getStartedBtn {
        width: 100% !important;
        display: block;
        text-align: center;
    }

    button {
        width: 100% !important;
    }

    .easilyManageDiv {
        padding: 20px;
        margin-top: 100px;

    }

    .actionMainContainer {
        margin-top: 50px;

        & .actionContainer {
            padding: 20px !important;
            margin-bottom: 40px !important;
        }
    }

    .levelUpContainer {
        gap: 15px;
        padding: 20px;
        margin-top: 100px;
        margin-bottom: 50px;

        & h3 {
            font-family: Poopins-Bold;
            font-size: 30px;

        }

        & .highlight {
            color: #A1A1A1;
        }

        & p {

            text-align: center !important;
        }

    }

    .useHyperspace {

        & div {
            padding: 30px;


            & h1 {
                font-family: Poopins-Bold;
                font-size: 60px;
                text-align: left;
                margin-bottom: 0px;
            }

            & img {
                position: relative;
                // top: 120px;
                // // bottom: 50px;
                // left: 150px;
                width: 40px;
            }
        }

        & img {
            width: 100%;
        }
    }

    .usespaces {

        padding: 20px;
    }


}

.planet44 {
    position: absolute;
    left: 10%;
    top: 43%;
    width: 3%;
}

.planet45 {
    position: absolute;
    right: 10%;
    top: 13%;
    width: 3%;
}

.planet46 {
    position: absolute;
    right: 10%;
    top: 12%;
}

.planet47 {
    position: absolute;
    left: 1%;
    top: 28%;
}

.planet48 {
    position: absolute;
    left: -2%;
    top: 55%;
}

.planet49 {
    position: absolute;
    right: -2%;
    top: 45%;
}

.planet50 {
    position: absolute;
    right: -20%;
    top: 10%;
}

.planet51 {
    position: absolute;
    left: -25%;
    top: 35%;
}

.planet52 {
    position: absolute;
    left: -25%;
    top: 55%;
    width: 15%;
}

.planet53 {
    position: absolute;
    right: -25%;
    top: 83%;
}

@media screen and(max-width:769px) {
    .planet44 {
        position: absolute;
        left: -15%;
        top: 55%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet45 {
        position: absolute;
        right: 10%;
        top: 32%;
        width: 30%;
        z-index: -20;
        display: none;
    }

    .planet46 {
        position: absolute;
        right: -5%;
        top: 17%;
        width: 40%;
        z-index: -20;
        display: none;
    }

    .planet47 {
        position: absolute;
        left: -5%;
        top: 29%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet48 {
        position: absolute;
        left: -2%;
        top: 56%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet49 {
        position: absolute;
        right: -5%;
        top: 49%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet50 {
        position: absolute;
        right: -20%;
        top: 19%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet51 {
        position: absolute;
        left: -25%;
        top: 47%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet52 {
        position: absolute;
        left: -10%;
        top: 87%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet53 {
        position: absolute;
        right: -10%;
        top: 98%;
        width: 50%;
        z-index: -20;
        display: none;
    }
}