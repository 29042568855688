.maincontainer {
    background-color: white !important;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 120px; */
}

.container {
    width: 90%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* gap: 20px; */
}

@media screen and (min-width:769px) {
    .container{
        width: 50%;
    }
    
}

.logo {
    width: 120px;
    align-self: center;
}

.container p {
    color: #111111;
    font-family: "Poopins-Medium";
    font-size: 14px;
}

.container h3 {
    color: #111111;
    font-family: "Poopins-Bold";
    font-size: 16px;
}

.container h4 {
    color: #111111;
    font-family: "Poopins-Regular" !important;
    font-size: 14px;
    font-weight: 0;
}

.trackingContainer {
    background-color: #F8F8F8;
    margin-top: 20px;
    overflow: scroll;
    width: 100%;
}

.trackingStatus {
    display: flex;
    justify-content: space-between;
}

.trackingDetails {
    padding: 20px;

}

.trackingDetails h2 {
    color: #111111;
    font-family: "Poopins-Medium";
    font-size: 14px;

}

.itemsContainer {
    margin-top: 20px;

}

/* .name{
    width: 30%;
    border: 2px solid red;
}

@media screen and (min-width:769px) {
    .name{
        width: 55% !important;
        border: 2px solid red;
    }
    
} */

.detailsContainer{
    width: 30%;
    /* border: 2px solid red; */
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
}

.detailsContainer h3 {
    font-size: 14px;
    /* align-self: center !important; */
    /* border: 2px solid red;
    width: 30%; */

}

.itemheadingsContainer {
    background-color: #F8F8F8;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.itemheadingsContainer h2 {
    color: #111111;
    font-family: "Poopins-Medium";
    font-size: 14px;
}

.itemsdetailsContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

 

.totalamountContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.deliveryContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

}

.shippingDetailsContainer {
    margin-top: 20px;
}
