h1,
p,
div {
    text-wrap: wrap;
    word-wrap: break-word;
}

.mainContainer {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contentImgContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    gap: 20px;
    width: 800px;
    max-width: 100% !important;

    // & button {
    //     font-family: Poopins-Regular;
    //     font-size: 20px;
    //     padding: 10px 15px 10px 15px;
    //     border: none;
    //     border-radius: 5px;
    //     max-width: fit-content;
    // }

    & h1 {
        font-size: 60px;
        font-family: Poopins-Bold;
        text-align: left;
    }

    & p {
        font-size: 20px;
        line-height: 29.26px !important;
        font-family: "Poopins-Regular";
        // margin-top: 50px;
        // margin-bottom: 50px;
    }
}

.getStartedBtn {
    padding: 10px 20px 10px 20px;
    font-family: Poopins-SemiBold;
    font-size: 20px;
    // font-weight: 700;
    line-height: 24px;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    width: fit-content;
    max-width: 100% !important;

}


.retailIsHere {
    background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
}

.column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: var(--mt-200);
}

image {
    max-width: 100%;
}

.heading {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 50px;
    border-radius: 30px;
    width: 1020px;
    max-width: 100%;

    & h1 {
        font-size: 60px;
        font-family: "Poopins-Bold";
    }

    & p {
        font-size: 20px;
        font-family: "Poopins-Medium";
    }

}

.main {
    margin: auto;
    width: 1300;
    max-width: 100%;

    & div {
        max-width: 100%;
        margin: auto;

        & h1 {
            max-width: fit-content;
            font-family: Poopins-Bold;
            font-size: 60px;
        }

        & p {
            font-family: Poopins-Regular;
            font-size: 20px;
            max-width: fit-content;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}

.getControl {
    margin-top: 30px !important;

    & .goingRetailHeading {
        width: 1020px;
        max-width: 100%;
        // text-align: center;
        margin: auto;
        margin-bottom: var(--mt-200);
        color: #FFFFFF;
    }

    & .socialMedia {
        max-width: 100%;
        width: 1000px;
        display: flex;
        flex-wrap: wrap;
        background-color: #181818;
        padding: var(--pd-200);
        border-radius: 30px;
        margin-bottom: 100px;
        color: #FFFFFF;

        & img {
            max-width: 100%;
        }



        & div {
            width: 420px;
            margin-bottom: 70px;

            & h2 {
                font-family: Poopins-Bold;
                font-size: 40px;
                text-align: left;
            }

            & p {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                margin-top: 20px;
            }

        }

    }

    & div {
        margin: auto;
        gap: 20px;
        margin-bottom: var(--mt-300);

        & .courierItems {
            padding: var(--pd-200);
            max-width: 100%;
            width: 450px;
            color: var(--white-text);
            border-radius: 20px;
            margin: 0px;
            margin-top: 20px;

            & span {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
            }

            & h2 {
                font-family: Poopins-Semibold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 20px;
            }

            & img {
                max-width: 100%;
            }
        }

        & .courierItems:nth-child(odd) {
            background-color: #0D0D0D;
        }

        & .courierItems:nth-child(even) {
            background-color: #222222;
        }

        & .getControlItems {
            width: 400px;
            padding: var(--pd-300);
            border-radius: 20px;
            color: var(--white-text);
            margin: 0px;
            max-width: 100%;

            & h2 {
                font-family: Poopins-Bold;
                font-size: 30px;
                margin-top: var(--mt-100);
            }

            & li {
                list-style-type: disc;
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                // line-height: 32px;
                letter-spacing: 0em;
                margin-top: var(--mt-100);
            }
        }

        & .getControlItems:nth-child(1) {
            background-color: var( --purple-dark-bg);
        }

        .getControlItems:nth-child(2) {
            background-color: var(--dark-purple);
        }
        .getControlItems:nth-child(3) {
            background-color: var(--dark-pink);
        }
    }

    & .shippingHeading {
        max-width: 1100px;
        padding: var(--pd-300);
        border-radius: 30px;
    }

}

.reachCustomers {

    // & .marketplaceAdvertising {
    //     max-width: 100%;
    //     width: 920px;
    //     padding: var(--pd-300);
    //     display: flex;
    //     margin-bottom: 20px;
    //     background-color: var(--white-bg);
    //     border-radius: 20px;
    //     position: relative;

    //     & div {
    //         width: 550px;
    //         margin: 0px;

    //         & h2 {
    //             font-family: Poopins-Bold;
    //             font-size: 40px;
    //             text-align: left;
    //         }

    //         & p {
    //             font-family: Poopins-Regular;
    //             font-size: 20px;
    //             font-weight: 500;
    //             line-height: 24px;
    //             letter-spacing: 0em;
    //             text-align: left;
    //         }
    //     }

    //     & img {
    //         width: 100px;
    //         height: 100px;
    //         position: absolute;
    //         bottom: 30px;
    //         right: 30px;
    //     }
    // }

    & div {
        margin: auto;
        gap: 20px;
        margin-bottom: var(--mt-300);

        & .reachCustomersItem {
            padding: var(--pd-200);
            max-width: 100%;
            width: 450px;
            color: var(--white-text);
            border-radius: 20px;
            margin: 0px;

            & p {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
            }

            & h2 {
                font-family: Poopins-Semibold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 20px;
            }

            & aside {
                text-align: right;
                width: 100%;
            }

            & img {
                width: 100px;
                margin-top: var(--mt-400);
            }
        }

        // & .reachCustomersItem:nth-child(1),
        // .reachCustomersItem:nth-child(4) {
        //     background-color: var(--purple-dark-bg);
        // }

        // & .reachCustomersItem:nth-child(2),
        // .reachCustomersItem:nth-child(3) {
        //     background-color: var(--purple-bg);
        // }
    }
}

.boostingPostHeading {
    padding: 60px;
    border-radius: 20px;
    background-color: #1C1C1C;
    margin-bottom: var(--mt-300);
    width: 1020px;
    max-width: 100%;

    & h1,
    p {
        color: var(--white-text);
        text-align: left;
    }

    & p {
        font-family: Poopins-Regular;
        font-size: 22px;
    }
}

.leadingCouriers {
    text-align: center;
    width: 1020px;
    max-width: 100%;
    margin-top: 100px !important;
    margin-bottom: 70px !important;

    & .boostingPostSubheading {
        color: var(--white-text);
        border-radius: 12px !important;
        background: linear-gradient(#1C1C1C,#1C1C1C) padding-box,
        linear-gradient(to right, #7123d6, #f26159) border-box;
        // border-radius: 50em;
        border: 2px solid transparent;
        padding: var(--pd-300);

        & h1 {
            margin-top: 0px;
        }

        & p {
            margin-bottom: 20px;
            margin-top: 30px;
            text-align: center;
            max-width: 100%;
        }


    }

}

@media screen and (max-width:769px) {

    .contentImgContainer {
        margin-top: 140px;

        // & h1,
        // p {
        //     text-align: center !important;
        // }
    }

    .contentImgContainer button {
        width: 100%;
    }

    .boostingPostHeading {
        padding: 40px !important;
    }

    h1 {
        font-size: 34px !important;
        line-height: normal !important;
    }

    p {
        font-size: 18px !important;
        line-height: normal !important;
    }

    h2 {
        font-size: 28px !important;
        line-height: normal !important;
    }

}

@media screen and (max-width:769px) {
    .heading {
        padding: 25px !important;
        border-radius: 30px;
        margin-bottom: 30px;

        & p {
            margin-top: 10px;
            text-align: left;
        }
    }

    .goingRetailHeading {
        text-align: center;
    }

    & .getControlItems {


        padding: 40px !important;

        & h2 {
            font-family: Poopins-Bold;
            font-size: 30px;
            margin-top: 10px !important;
            margin-bottom: 5px !important;
        }



        & li {

            font-size: 18px !important;

        }
    }

    .boostingPostSubheading {



        & p {
            text-align: center !important;
            max-width: 100%;
            margin-top: 20px;
        }


    }

    .marketplaceAdvertising {

        // align-items: center !important;
        & div {


            & p {
                margin-top: 10px !important;
            }
        }

        & img {
            width: 100px !important;
            height: 100px !important;
            position: relative !important;
            bottom: 10px !important;
            top: 5px !important;
            // right: 30px;
            object-fit: contain;
            align-self: flex-end;
            justify-self: flex-end;
        }
    }


}

.planet33 {
    position: absolute;
    left: 5%;
    top: 3%;
}

.planet34 {
    position: absolute;
    right: 5%;
    top: 13%;
}

.planet35 {
    position: absolute;
    right: 5%;
    top: 30%;
    z-index: -1;
}

.planet36 {
    position: absolute;
    left: 5%;
    top: 20%;
}

.planet37 {
    position: absolute;
    right: 5%;
    top: 42%;
}

.planet38 {
    position: absolute;
    right: 5%;
    top: 42%;
}

.planet39 {
    position: absolute;
    left: -2%;
    top: 53%;
}

.planet40 {
    position: absolute;
    right: 5%;
    top: 80%;
}

.planet41 {
    position: absolute;
    left: -2%;
    top: 86%;
}

.planet42 {
    position: absolute;
    left: -3%;
    top: 95%;
}

.planet43 {
    position: absolute;
    right: 3%;
    bottom: -1%;
}

@media screen and(max-width:769px) {
    .planet33 {
        position: absolute;
        left: 5%;
        top: 3%;
        width: 20%;
        z-index: -1;
        display: none;
    }

    .planet34 {
        position: absolute;
        right: 0%;
        top: 10%;
        z-index: -1;
        width: 40%;
        display: none;
    }

    .planet35 {
        position: absolute;
        right: -7%;
        top: 33%;
        width: 50%;
        z-index: -1;
        display: none;
    }

    .planet36 {
        position: absolute;
        left: -3%;
        top: 20%;
        width: 40%;
        z-index: -1;
        display: none;
    }

    .planet37 {
        position: absolute;
        right: 0%;
        top: 43%;
        width: 50%;
        z-index: -1;
        display: none;
    }

    .planet38 {
        position: absolute;
        right: 0%;
        top: 43%;
        width: 50%;
        z-index: -1;
        display: none;
    }

    .planet39 {
        position: absolute;
        left: -2%;
        top: 52%;
        width: 70%;
        z-index: -1;
        display: none;
    }

    .planet40 {
        position: absolute;
        right: 0%;
        top: 69%;
        width: 30%;
        z-index: -1;
        display: none;
    }

    .planet41 {
        position: absolute;
        left: -2%;
        top: 89%;
        width: 50%;
        z-index: -1;
        display: none;
    }

    .planet42 {
        position: absolute;
        left: -1%;
        top: 94%;
        width: 50%;
        z-index: -1;
        display: none;

    }

    .planet43 {
        position: absolute;
        right: 1%;
        bottom: 0%;
        width: 49%;
        z-index: -1;
        display: none;
    }
}


@media screen and (min-width:769px) {}

@media screen and (min-width:992px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:992px) {}

@media screen and (max-width:600px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:996px) {}


@media screen and (min-width:1400px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}


@media screen and (min-width:996px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}