.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 100px;
    width: 1200px;
    max-width: 100%;
    color: #FFFFFF;
    // margin-top: 100px !important;
    // align-items: center;
}
h1,h2,h3,h4{
    font-family: "Poopins-Medium";
    // font-size: 12px !important;
}
p{
    font-family: "Poopins-Regular";
}