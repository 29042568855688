.mainContainer {
    width: 100%;
    background-image: url('../../../public/assets/images/Group 18532.png');
    background-size: cover;
    background-position: center;
    background-attachment: local;
    background-repeat: no-repeat;
    background-color: var(--black-bg);
    min-height: 1150px;
    height: max-content;
    padding-bottom: 10px;
}

@media(max-width:500px) {
    .mainContainer {
        background-image: none;
        background-color: #0D0D0D;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #0D0D0D; */
    width: 100%;
}

.contentContainer {
    display: flex;
    justify-content: center;
    padding-top: var(--mt-500);
    padding-bottom: var(--mt-500);
    width: 100%;
}

.planet1 {
    width: 4%;
    object-fit: contain;
    align-self: flex-start;
    margin-top: 5%;
    position: absolute;
    margin-left: 5%;
}

.content {
    display: flex;
    width: 90%;
    gap: 10%;
    padding-top: 5%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width:769px) {
    .content {
        flex-direction: row;

    }

}

.waitlistContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-start;
    margin-bottom: 30px;
}

@media screen and (min-width:769px) {
    .waitlistContent {
        width: 35%;
    }

}

.waitlistContent h1 {
    padding-left: var(--pl-100);
    font-size: 60px;
    font-weight: 700;
    font-family: "Poopins-Bold";
    background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* @media screen and (max-width:769px) {
    .waitlistContent h1 {
        text-align: center;

    }

} */

.waitlistContent p {
    padding-left: var(--pl-100);
    padding-top: var(--fs-100);
    color: #DFDFDF;
    font-family: "Poopins-Regular";
    font-size: 20px;
    font-weight: 400;
}

.formContainer {
    background-color: #181818;
    padding: var(--pl-100);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

@media screen and (min-width:769px) {
    .formContainer {
        width: 45%;
    }

}

.inputContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer label {
    font-size: var(--fs-900);
    color: #FFFFFF;
    font-family: "Poopins-Medium";
}

.inputContainer div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.agreeLabel {
    font-size: 15px;
    color: #FFFFFF;
    font-family: "Poopins-Medium";

}

.input {
    padding: 20px;
    background-color: #242424;
    color: #727272;
    /* border: 2px solid white; */
    transition: border 0.3s;
    border: 0px;
    font-size: 24px;
    font-weight: 500;
}

input.input:focus {
    border-image-source: repeating-linear-gradient(to right, #7123d6, #f26159);
    border-image-slice: 1;
    /* Use 1 to slice the gradient evenly */
    border-image-width: 2px;
    /* Set the width of the border */
    border-image-outset: 0;
    /* Set outset to 0 */
    border-image-repeat: round;
    /* Repeat the gradient */
    border-color: transparent;
}

textarea {
    resize: none;
    font-family: 'Poopins-Medium';
    /* font-size: 20px; */
    padding: 20px;
    background-color: #242424;
    color: #727272;
    /* border: 2px solid white; */
    transition: border 0.3s;
    border: 0px;
    font-size: 24px;
    font-weight: 500;
}

textarea:focus {
    border-image-source: repeating-linear-gradient(to right, #7123d6, #f26159);
    border-image-slice: 1;
    /* Use 1 to slice the gradient evenly */
    border-image-width: 2px;
    /* Set the width of the border */
    border-image-outset: 0;
    /* Set outset to 0 */
    border-image-repeat: round;
    /* Repeat the gradient */
    border-color: transparent;
}

.checkboxContainer {
    display: flex;
    align-items: center;
}

.checkboxContainer label {
    padding: 0px;
    margin: 0px;
}

.button {
    display: inline-block;
    box-sizing: border-box;
    padding: 20px 0px;
    border-radius: 4px;
    width: 100%;
    background: #0D0D0D;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

/* .button1 {
    background-color: #8226f7;
    padding: 10px 0px;
    width: 100%;
    top: var(--bt-300);
    left: var(--bt-400);
    border-radius: 0%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
} */

/* .button1:hover {
    background-color: #fe655d;
} */

.btn {
    font-size: var(--fs-900);
    position: relative;
    text-decoration: none;
    color: #fff;
    font-family: "Poopins-Medium";
    text-align: center;
    padding: 0px;
    margin: 0px;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.btn:hover {
    text-decoration: none;
}

button:focus {
    outline: none;
}

.planet2 {
    width: 10%;
    object-fit: contain;
    position: absolute;
    align-self: flex-end;
    margin-right: 5%;
    margin-top: 5%;
}

.imgContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.planet3 {
    width: 30%;
    object-fit: contain;
}

.planet4 {
    width: 50%;
    object-fit: contain;
    margin-top: var(--ml-100);
}

.errorMsg {
    color: red;
    font-family: "Poopins-Regular";
}

.success {
    color: green;
    font-family: "Poopins-Regular";
}

.CardContainer {
    display: flex;
    width: 70%;
    align-self: center;
    justify-content: space-between;
    margin-top: 5%;
}

.Card {
    background-color: rgba(24, 24, 24, 1);
    padding: 3%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 30%;
}

.cardHeading {
    color: rgba(223, 223, 223, 1);
    font-family: "Poopins-Medium";
    font-size: 22px;
    width: 70%;
}

.description {
    color: rgba(223, 223, 223, 1);
    font-family: "Poopins-Regular";
    font-size: 14px;
}

.voilet {

    color: rgba(130, 38, 247, 1);

}

.calltoactionbtn {

    font-family: "Poopins-Medium";
    /* width: 20%; */
    padding: 10px;
    color: rgba(255, 255, 255, 1);
    /* cursor: pointer; */
    background: rgba(255, 255, 255, 0.1);
    /* border: none; */
    border-radius: 8px;
    font-size: 12px;
    border: 0px;
    outline: none;
}