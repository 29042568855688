* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

button:active {
  transform: translateY(4px);
}

body {
  /* color: #fff; */
  background-color: #0D0D0D;
  transition: background-color 1s ease;
  background-size: contain;
  width: 100%;
  /* background-size: cover; */
  /* line-height: 1.5; */
  /* font-weight: 400; */
  overflow-x: hidden !important;
  position: relative;
  z-index: -3;
}

.slick-dots {
  bottom: -75px !important;
}

@font-face {
  font-family: "Poopins-Regular";
  src: url(../public/assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Poopins-Medium";
  src: url(../public/assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: "Poopins-SemiBold";
  src: url(../public/assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: "Poopins-Bold";
  src: url(../public/assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "Poopins-Italic";
  src: url(../public/assets/fonts/Montserrat-Italic.ttf);
}

:root {
  --purple-text: #8226F7;
  --black-text: #222222;
  --white-text: #FFFFFF;
  --pink-text: #FF725E;
  --gray-text: #A1A1A1;

  --black-bg: #0D0D0D;
  --purple-bg: #A767F9;
  --purple-dark-bg: #8226F7;
  --purple-light-bg: #E6D4FD;
  --white-bg: #FFFFFF;
  --pink-dark-bg: #FE746D;
  --pink-bg: #FE938E;
  --pink-bg-light: #FFE0DF;
  --dark-pink:#FE655D;
  --dark-purple:#C046AA;

  --pt-100: 20px;
  --pt-200: 160px;
  --pt-300: 5px;
  --pt-400: 180px;
  --pd-100: 15px;
  --pd-200: 30px;
  --pd-300: 50px;
  --mt-100: 10px;
  --mt-200: 100px;
  --mt-300: 130px;
  --mt-400: 60px;
  --mt-500: 50px;
  --mt-600: 80px;
  --mt-700: 8%;
  --fs-10: 70px;
  --fs-16: 16px;
  --fs-20: 0px;
  --fs-30: 30px;
  --fs-100: 30px;
  --fs-200: 65px;
  --fs-300: 18px;
  --fs-350: 20px;
  --fs-400: 30px;
  --fs-600: 22px;
  --fs-700: 8px;
  --fs-900: 17px;
  --wd-200: 270px;
  --mb-100: 8px;
  --ml-100: 90px;
  --pl-100: 40px;
  --pl-200: 5%;
  --im-300: 200px;
  --gp-100: 60%;
  --hd-100: 38px;
  --hd-200: 210px;
  --bt-100: 140px;
  --bt-200: 60px;
  --bt-300: -4px;
  --bt-400: 5px;
  --bt-500: 25px;
  --bt-600: 10px;
  --bt-700: 10px;
  --bt-800: 240px;
  --ar-40: 40px;
}

@media (max-width: 1100px) and (min-width: 601px) {
  :root {
    --pt-100: 15px;
    --pt-200: 40px;
    --pt-300: 3.5px;
    --pt-400: 100px;
    --pd-100: 10px;
    --pd-200: 20px;
    --pd-300: 32px;
    --mt-100: 7.5px;
    --mt-200: 40px;
    --mt-300: 60px;
    --mt-400: 35px;
    --mt-500: 38px;
    --mt-600: 60px;
    --mt-700: 6%;
    --fs-10: 0px;
    --fs-20: 2px;
    --fs-30: 23px;
    --fs-100: 22px;
    --fs-200: 45px;
    --fs-300: 15px;
    --fs-350: 18px;
    --fs-400: 25px;
    --fs-600: 20px;
    --fs-700: 7px;
    --fs-900: 11px;
    --wd-200: 200px;
    --mb-100: 20px;
    --ml-100: 65px;
    --pl-100: 30px;
    --im-300: 150px;
    --pl-200: 5%;
    --gp-100: 45%;
    --hd-100: 36px;
    --hd-200: 170px;
    --bt-100: 100px;
    --bt-200: 45px;
    --bt-300: -3px;
    --bt-400: 3.5px;
    --bt-500: 14px;
    --bt-600: 6px;
    --bt-700: 4px;
    --bt-800: 180px;
    --ar-40: 20px;
  }
}

@media (max-width: 600px) {
  :root {
    --pt-100: 10px;
    --pt-200: 10px;
    --pt-300: 2.5px;
    --pt-400: 50px;
    --pd-100: 7.5px;
    --pd-200: 25px;
    --pd-300: 25px;
    --mt-100: 8px;
    --mt-200: 20px;
    --mt-300: 30px;
    --mt-400: 20px;
    --mt-500: 25px;
    --mt-600: 40px;
    --mt-700: 4%;
    --fs-10: 0px;
    --fs-16: 14px;
    --fs-20: 60px;
    --fs-30: 30px;
    --fs-100: 18px;
    --fs-200: 24px;
    --fs-300: 14px;
    --fs-400: 24px;
    --fs-600: 18px;
    --fs-700: 4px;
    /* --fs-900: 8.5px; */
    --fs-900: 14px;
    --wd-200: 135px;
    --mb-100: 4px;
    --ml-100: 35px;
    --pl-100: 20px;
    --pl-200: 5%;
    --gp-100: 30%;
    --hd-100: 28px;
    --hd-200: 140px;
    --bt-100: 70px;
    --bt-200: 40px;
    /* --bt-200: 30px; */
    --bt-300: -2px;
    --bt-400: 2.5px;
    --bt-500: 6px;
    --bt-600: 2px;
    --bt-700: -4px;
    --bt-800: 160px;
    /* --bt-800: 120px; */
    --im-300: 80px;
    --ar-40: 15px;
  }
}
