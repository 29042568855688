h1,
p,
div {
    text-wrap: wrap;
    word-wrap: break-word;
}

.mainContainer {
    width: 100%;
    // background-image: url(../../../public/assets/images/sass-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // margin-bottom: 5%;
    // padding-left: 10px;
    // padding-right: 10px;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* justify-content: center; */
    /* background-color: #0D0D0D; */
    /* padding-top: 8%; */
    /* padding-bottom: 12%; */
    width: 800px;
    max-width: 100%;
    margin-top: 150px !important;

    // margin-top: 140px;
    margin-bottom: 160px;
    height: 65vh !important;


    /* border: 2px solid red; */
    gap: 35px;
}

.mainHeadings {
    font-size: 60px;
    font-family: "Poopins-Bold";
    padding: 0px !important;
    margin: 0px !important;
    color: #FFFFFF;
    text-align: center;
    /* width: 100%; */

}



.highlight {
    color: #A1A1A1;
}

.getStartedBtn {
    padding: 10px 20px 10px 20px;
    font-family: Poopins-SemiBold;
    font-size: 20px;
    // font-weight: 700;
    line-height: 24px;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 5px;
    border: none;
    // margin-top: 20px;
    width: fit-content;
    max-width: 100%;
    text-decoration: none;

}

.littleManiContainer {
    background-color: #FFFFFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

}


.littleBgContainer {
    background-color: #1c1c1c;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 100%;
    width: 100vw;
    padding-top: 150px;
    // padding-bottom: 150px !important;
    // border: 2px solid red;
}

.littleContentDiv {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1100px;
    max-width: 100%;
    gap: 35px;
    // border: 2px solid red;
}

@media(max-width:769px) {
    .littleContentDiv {
        width: 95%;
    }

    .contentContainer .getStartedBtn {
        width: 100%;
    }
}

.littleContentDiv p {
    font-size: 20px;
    font-family: "Poopins-Medium";
    text-align: center !important;
    margin-top: 20px;
    color: #FFFFFF;
}

.retailIsHere {
    background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cardContainer {
    width: 800px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@media(max-width:769px) {
    .cardContainer {
        width: 100%;
    }

    .cardContainer p {
        text-align: center !important;
    }
}

.cardContainer p {
    font-size: 22px;
    font-family: "Poopins-Medium";
    // text-align: center;
}

.card1 {
    background-color: #000000;
    border-radius: 16px;
    padding: 40px;
    width: 700px;
    max-width: 100%;
}

.card1 h4 {
    font-family: "Poopins-Bold";
    color: #FFFFFF;
    font-size: 32px;
    text-align: center;
}

.convictionContainer {
    margin-top: 150px;
    margin-bottom: 50px;
    /* margin-bottom: 80px; */
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media(max-width:769px) {
    .convictionContainer {
        width: 95%;
        margin-top: 100px;
    }

    .convictionContent p {
        text-align: center !important;
    }
}

.convictionContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;

}

.convictionContent p {
    font-size: 18px;
    font-family: 'Poopins-Medium';
    text-align: center;
    color: #FFFFFF;
}

.convictionContent span {
    font-family: "Poopins-Bold";
}


.missionstatement1 {
    font-family: "Poopins-Medium";
    color: rgba(255, 255, 255, 1);
    font-size: 22px;
    /* margin-top: 2%; */

}

.missionstatement {

    color: rgba(130, 38, 247, 1);

}



.hyperspacetext {
    background: linear-gradient(90deg, rgba(254, 101, 93, 1) 23.72%, rgba(130, 38, 247, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.description {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    font-size: 20px;
    text-align: center !important;
}

.plugAndPlay {
    font-family: "Poopins-Medium";
    color: rgba(255, 255, 255, 1);
    font-size: 14px;

}

.planet1 {
    width: 30px;
    height: 30px;
    object-fit: contain;
    position: absolute;
    align-self: flex-start !important;
    justify-self: flex-start !important;
    margin-left: 5%;
    margin-top: 3%;
}

.planet2 {
    width: 30px;
    height: 30px;
    object-fit: contain;
    position: absolute;
    /* margin-right: 8%; */
    margin-top: 70%;
    align-self: flex-end;
}

@media screen and (min-width:769px) {
    .planet1 {
        margin-left: 5%;
        display: none;
    }

    .planet2 {
        margin-right: 8%;
        margin-top: 35%;
        display: none;

    }

}

.planet3 {
    width: 15%;
    object-fit: contain;
}

.content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.content h1 {
    color: #FFFFFF;
    font-family: "Poopins-Bold";
    font-size: var(--pl-100);
    text-align: center;
}

.content h5 {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    font-size: var(--fs-16);
    text-align: center;
    padding-top: var(--pt-100);
    padding-bottom: var(--pt-100);
}

.content p {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    text-align: center;
    font-size: var(--fs-16);
    width: 100%;
}

.whyhyperspaceContainer {
    /* background-color: #111111; */
    background-color: rgba(24, 24, 24, 1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    /* margin-top: 5%; */
}

@media screen and (min-width:769px) {
    .whyhyperspaceContainer {
        flex-direction: row;
        align-items: center;
    }
}

.whyhyperspaceImgContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    order: 0;
}

.whyhyperspaceImgContainer img {
    width: 100%;
    object-fit: contain;
}

@media screen and (min-width:769px) {
    .whyhyperspaceImgContainer {
        width: 60%;
    }

}

.whyhyperspaceContent {
    width: 100%;
    padding-top: var(--mt-500);
    padding-bottom: var(--mt-500);
    display: flex;
    justify-content: center;
    order: 1;
}

.whycontent {
    width: 80%;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width:769px) {
    .whyhyperspaceContent {
        width: 60%;
        order: 0;
    }

    .whycontent {
        width: 50%;
    }
}

.whereDoesText {
    font-family: "Poopins-SemiBold";
    color: rgba(255, 255, 255, 1);
    font-size: 20px;

}

/* .whyhyperspaceContent h2 {
    color: #FFFFFF;
    font-family: "Poopins-Bold";
    font-size: var(--fs-100);
}

.whyhyperspaceContent h3 {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    font-size: var(--fs-300);
    padding-top: 1%;
}

.whyhyperspaceContent h4 {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    font-size: var(--fs-16);
    padding-top: var(--pt-100);
    padding-bottom: var(--pt-100);
}

.whyhyperspaceContent h4 span {
    color: #E6D11B;
}

.whyhyperspaceContainer p {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    font-size: var(--fs-16);
}

.whyhyperspaceContainer p span {
    font-family: "Poopins-Italian";
} */

.crewContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    width: 800px;
    align-self: center;
    margin-top: 5%;
    margin-bottom: 40px;
    gap: 20px;
}

.roleContainer {
    align-self: center;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 5%;
}

.meetCrewContainer {
    // background-color: #040207;
    padding-top: var(--mt-500);
    padding-bottom: var(--mt-500);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.meetCrew {
    width: 80%;
}

.headings {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headings h2 {
    color: #FFFFFF;
    font-family: "Poopins-Bold";
    font-size: var(--pl-100);
}

.teamMembersContainer {
    list-style-type: none;
    padding-left: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--mt-500);
    width: 100%;
    justify-content: space-between;
}

.teamMemberCard {
    display: flex;
    flex-direction: column;
    width: 47%;
}

@media screen and (min-width:769px) {
    .teamMemberCard {
        width: 22%;
    }

    .teamMembersContainer {
        justify-content: flex-start;
        gap: 30px;
    }

}

.teamMemberCard img {
    object-fit: contain;
    width: 100%;
}

.teamMemberCard h4 {
    padding-top: var(--pt-100);
    font-family: "Poopins-Medium";
    font-size: var(--fs-300);
}

.teamMemberCard p {
    font-family: "Poopins-Regular";
    font-size: 13px;
}

.starShipContainer {
    background-color: #0D0D0D;
    display: flex;
    padding-top: var(--mt-600);
    padding-bottom: var(--mt-600);
    width: 100%;
}

.starShipContainer img {
    width: 11%;
    object-fit: contain;
    position: absolute;
    margin-top: 2%;
}

@media screen and (min-width:769px) {
    .starShipContainer img {
        margin-left: 11%;
    }

}

.starShipContent {
    width: 100%;
    display: flex;
    justify-content: center;
}

.starship {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.starship h3 {
    color: #FFFFFF;
    font-family: "Poopins-Bold";
    padding-bottom: 5px;
    font-size: var(--fs-100);
    text-align: center;
}

.starship p {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    font-size: 16px;
    text-align: center;
}

.button {
    display: inline-block;
    box-sizing: border-box;
    background-color: #fe655d;
    width: 35%;
    margin-top: var(--mt-500);
}

@media screen and (min-width:769px) {
    .button {
        width: 15%;
    }

}

.button:hover {
    background-color: #8226f7;
}

// .button1 {
//     background-color: #8226f7;
//     width: 100%;
//     top: var(--bt-300);
//     left: var(--bt-400);
//     padding: 8% 0px;
//     border-radius: 0%;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.button1:hover {
    background-color: #fe655d;
}

// .btn {
//     font-size: var(--fs-900);
//     position: relative;
//     text-decoration: none;
//     color: #fff;
//     text-align: center;
//     padding: 0px;
//     margin: 0px;
//     font-family: "Poopins-Regular";
// }

.btn:hover {
    text-decoration: none;
}

.shadeBgContainer {
    background-color: #0D0D0D;
    background-image: url(../../../public/assets/images/stars.png);
    // background-size: cover;
    width: 100%;
    border-radius: 16px;
    /* margin-top: 5%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* justify-content: space-around; */
    padding-top: 100px;
    // padding-bottom: 100px;

}

.shadeContainer {
    width: 900px;
    max-width: 95%;
    background-color: #181818;
    border-radius: 16px;
    padding: 40px;
    margin-top: 100px;

    & div {
        & span {
            background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: "Poopins-Bold";
            font-size: 60px;
        }
    }
}

@media screen and(max-width:769px) {
    p {
        text-align: left !important;
    }

    .shadeContainer {
        padding: 20px;

        & div {
            & span {
                font-size: 30px
            }
        }
    }
}

// .shade {
//     position: absolute;
//     width: 23%;
//     object-fit: contain;
//     top: 60%;
//     z-index: 999;
// }

.weText {
    color: #FFFFFF;
    font-family: "Poopins-Regular";
    font-size: 32px;
    /* z-index: 999; */
    /* position: relative; */
}

.respectText {
    color: #8226F7;
    font-family: "Poopins-Bold";
    font-size: 60px;
    /* z-index: 999; */
    /* position: relative; */

}

.shadeContent {
    background-color: #262626;
    border-radius: 8px;
    padding: 3%;
    width: 40%;
}

.questionsContainer {
    max-width: 95%;
    width: 1020px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 80px;

}

.questionsBox1 {
    width: 100%;
    display: flex;
    gap: 20px;
}

.questionsCard1 {
    background-color: #444444;
    display: flex;
    flex-direction: column;
    // gap: 30px;
    padding: 40px;
    width: 50%;
    border-radius: 16px;
}

@media(max-width:769px) {
    .questionsBox1 {
        flex-direction: column;
    }

    .questionsCard1 {
        width: 100%;
    }
}

.questionsBox2 {
    background-color: #222222;
    display: flex;
    flex-direction: column;
    // gap: 30px;
    padding: 60px;
    width: 100%;
    border-radius: 16px;

}

.questionsBox2 p {
    font-size: 22px;
    font-family: "Poopins-Medium";
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 30px;
}

.questionsCard1 p {
    font-size: 22px;
    font-family: "Poopins-Medium";
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 30px;
}

.cardheadings {
    font-size: 32px;
    font-family: "Poopins-Bold";
    color: #FFFFFF;

}

@media screen and (max-width:769px) {

    h1 {
        font-size: 34px !important;
        line-height: normal !important;
    }

    p {
        font-size: 18px !important;
        line-height: normal !important;
    }

    h2 {
        font-size: 28px !important;
        line-height: normal !important;
    }

}

@media screen and (max-width:769px) {
    .contentContainer {
        margin-top: 100px !important;
        // margin-top: 140px;
        margin-bottom: 100px;
    }

    .crewContainer {

        // align-self: center;
        margin-top: 50px !important;
        margin-bottom: 5%;
        gap: 20px;
        // border: 2px solid red;
        max-width: 100%;
        padding: 20px;
    }

    .questionsBox2 {

        padding: 40px;
    }

    .card1 h4 {
        // font-family: "Poopins-Bold";
        // color: #FFFFFF;
        font-size: 24px;
        text-align: center;
    }

    .shadeBgContainer {
        padding-bottom: 10px;
    }

}

.planet54 {
    position: absolute;
    left: 5%;
    top: 10%;
    z-index: -1;
}

.planet55 {
    position: absolute;
    right: 5%;
    z-index: -1;
    top: 20%;
}

.planet56 {
    position: absolute;
    left: 5%;
    z-index: -1;
    top: 47%;
}

.planet57 {
    position: absolute;
    left: 5%;
    z-index: -1;
    top: 25%;
}

.planet58 {
    position: absolute;
    z-index: -1;
    right: 5%;
    top: 30%;
}

.planet59 {
    z-index: -1;
    position: absolute;
    left: -4%;
    top: 38%;
}

.planet60 {
    position: absolute;
    right: 4%;
    z-index: -1;
    top: 45%;
}

.planet61 {
    position: absolute;
    left: 4%;
    z-index: -1;
    top: 62%;
}

.planet62 {
    position: absolute;
    z-index: -1;
    right: 4%;
    top: 73%;
}

.planet63 {
    position: absolute;
    left: 4%;
    z-index: -1;
    top: 84%;
}

.planet64 {
    position: absolute;
    right: 4%;
    z-index: -1;
    top: 90%;
}

@media screen and (max-width:769px) {
    .planet54 {
        position: absolute;
        left: 0%;
        top: 30%;
        width: 30%;
        z-index: -1;
        display: none;
    }

    .planet55 {
        position: absolute;
        right: 0%;
        top: 22%;
        width: 30%;
        z-index: -1;
        display: none;
    }

    .planet56 {
        position: absolute;
        left: 0%;
        top: 51%;
        width: 30%;
        z-index: -1;
        display: none;
    }

    .planet57 {
        position: absolute;
        left: 0%;
        top: 27%;
        width: 50%;
        z-index: -1;
        display: none;
    }

    .planet58 {
        position: absolute;
        right: 5%;
        top: 30%;
        z-index: -1;
        display: none;
    }

    .planet59 {
        position: absolute;
        left: -4%;
        top: 38%;
        z-index: -1;
        display: none;
    }

    .planet60 {
        position: absolute;
        right: 0%;
        top: 57%;
        width: 40%;
        z-index: -1;
        display: none;
    }

    .planet61 {
        position: absolute;
        left: -15%;
        top: 66%;
        width: 50%;
        z-index: -1;
        display: none;
    }

    .planet62 {
        position: absolute;
        right: 0%;
        top: 76%;
        width: 50%;
        z-index: -1;
        display: none;
    }

    .planet63 {
        position: absolute;
        left: -5%;
        top: 82%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet64 {
        position: absolute;
        right: -20%;
        top: 91%;
        z-index: -1;
        width: 50%;
        display: none;
    }
}