.dropdown {
    position: relative;
    display: inline-block;
    color: #d3d4d4;
}

.dropdown span{
    font-family: "Poopins-Regular";
    font-size: var(--fs-300);
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #0D0D0D;
    color: #d3d4d4;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px 8px;
    z-index: 1;

    & li {
        // border-bottom: 1px solid #d3d4d4;
        padding-bottom: 5px;
        padding-top: 5px;
    }
}

.dropDowmActive{
    display: none;
    position: relative;
    background-color: #0D0D0D;
    color: #d3d4d4;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px 8px;
    // z-index: 1;

    & li {
        // border-bottom: 1px solid #d3d4d4;
        padding-bottom: 5px;
        padding-top: 5px;
    }

}

.dropDowmActive.mobileActive{
    display: block !important;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.navContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    /* background-color: white; */
    width: 100%;
    padding: 25px 0px;
    background-color: #0D0D0D;
    z-index: 1;
    height: 80px;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%;
}

.logoContainer img {
    /* filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg) brightness(119%) contrast(115%); */
    height: var(--hd-100);
    width: var(--hd-200);
    object-fit: contain;
}

.navItems {
    display: none;
}

@media screen and (min-width:996px) {
    .navItems {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 60%;
        gap: 30px;
        margin: 0px;
    }
}

.mobilenavItems {
    list-style: none;
    align-items: center;
    margin: 0px;
    gap: 30px;
    font-family: "Poopins-Medium";
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    background-color: #070707;
    z-index: 9999;
}

.mobilenavItems.active {

    transform: translateX(0%);
}

.navItems li a {
    text-decoration: none;
    color: #d3d4d4;
    /* font-weight: 200; */
    font-family: "Poopins-Regular";
    font-size: var(--fs-300);
}

.mobilenavItems li:nth-child(2) a {
    color: #FE655D;
}

.mobilenavItems li a {
    text-decoration: none;
    color: #d3d4d4;
    /* font-weight: 200; */
    font-family: "Poopins-Regular";
    font-size: var(--fs-300);
}

.hamburger {
    width: 40px;
    position: relative;
    cursor: pointer;
    line-height: 10px;
    z-index: 1500;
    height: 30px;

}

@media screen and (min-width:996px) {
    .hamburger {
        display: none;
    }
}

.hamburger .line {
    width: 80%;
    height: 3px;
    background-color: #FE655D;
    position: absolute;
    display: block;
    right: 0;
    transition: all 650ms;
}

.hamburger .line:nth-child(1) {
    top: 0;
}

.hamburger .line:nth-child(2) {
    top: 50%;
    margin-top: -1px;
    width: 50%;
}

.hamburger .line:nth-child(3) {
    top: 100%;
    margin-top: -2px;
    width: 20%;
}

.hamburger.active .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
    width: 70%;
}

.hamburger.active .line:nth-child(2) {
    opacity: 0;
}

.hamburger.active .line:nth-child(3) {
    transform: translateY(-20px) rotate(-45deg);
    width: 70%;
}