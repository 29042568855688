h1,
p,
div {
    text-wrap: wrap;
    word-wrap: break-word;
}

.mainContainer {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contentImgContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // font-size: 60px;
    color: #FFFFFF;

    & button {
        padding: 10px 20px 10px 20px;
        font-family: Poopins-SemiBold;
        font-size: 20px;
        // font-weight: 700;
        line-height: 24px;
        background-color: #FFFFFF;
        color: #000000;
        border-radius: 5px;
        border: none;
        margin-top: 20px;
        width: fit-content;
        max-width: 100%;
    }

    & h1 {
        font-size: 60px;
        font-family: Poopins-Bold;
        letter-spacing: 0em;
        text-align: left;
    }

    & p {
        font-size: 20px;
        line-height: 29.26px !important;
        font-family: "Poopins-Regular";
        // margin-top: 20px;
        // margin-bottom: 20px;
    }
}

.retailIsHere {
    background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
}

.column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: var(--mt-200);
}

image {
    max-width: 100%;
}

.heading {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 50px;
    border-radius: 30px;
    width: 1020px;
    max-width: 100%;

    & h1 {
        font-size: 60px;
        font-family: "Poopins-Bold";
    }

    & p {
        font-size: 20px;
        font-family: "Poopins-Regular";
    }

}




.main {
    margin: auto;
    width: 100%;
    max-width: 1300px;

    & div {
        width: 100%;
        max-width: fit-content;
        margin: auto;

        & h1 {
            max-width: fit-content;
            font-family: Poopins-Bold;
            font-size: 60px;
        }

        & p {
            margin-top: var(--mt-100);
            font-family: Poopins-Regular;
            font-size: 20px;
            max-width: fit-content;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}

.marketplaceAdvertising {
    max-width: 100% !important;
    width: 920px !important;
    padding: var(--pd-300);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: var(--white-bg);
    border-radius: 20px;
    // position: relative;
    margin-top: 40px !important;

    & div {
        // width: 550px;
        // max-width: 100%;
        margin: 0px;
        // border: 2px solid red;

        & h2 {
            font-family: Poopins-Bold;
            font-size: 40px;
            text-align: left;
        }

        & p {
            font-family: Poopins-Regular;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    & img {
        width: 100px;
        height: 100px;
        // position: relative;
        // bottom: 30px;
        // right: 30px;
    }
}



.getControl {

    // margin-top: 50px;
    & .socialMedia {
        max-width: 100%;
        width: 920px;
        display: flex;
        flex-wrap: wrap;
        background-color: "#181818";
        padding: var(--pd-200);
        border-radius: 30px;
        margin-bottom: 20px;

        & img {
            max-width: 100%;
        }



        & div {
            width: 400px;
            margin-bottom: 30px;

            & h2 {
                font-family: Poopins-Bold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 20px;

            }

            & p {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
            }
        }

    }

    & div {
        margin: auto;
        gap: 20px;
        // margin-bottom: var(--mt-300);

        & .courierItems {
            position: relative;
            padding: var(--pd-200);
            max-width: 100%;
            width: 450px;
            color: var(--white-text);
            border-radius: 20px;
            height: 450px;
            margin: 0px;
            // margin-top: 15px;

            & img {
                max-width: 100%;
            }

            & span {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
            }

            & h2 {
                font-family: Poopins-Semibold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 20px;
            }

            & div {
                margin: 0px;
            }
        }

        & .courierItems:nth-child(1),
        .courierItems:nth-child(4),
        .courierItems:nth-child(5) {
            background-color: var(--dark-pink);
        }

        & .courierItems:nth-child(2),
        .courierItems:nth-child(3),
        .courierItems:nth-child(6) {
            background-color: var(--purple-dark-bg);
        }
    }

    & .shippingHeading {
        max-width: 1100px;
        padding: var(--pd-300);
        border-radius: 30px;
    }

}



.reachCustomers {
    & div {
        margin: auto;
        gap: 20px;

        & .reachCustomersItem {
            padding: var(--pd-200);
            max-width: 100%;
            width: 450px;
            color: var(--white-text);
            border-radius: 20px;
            margin: 0px;

            & span {
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
            }

            & h2 {
                font-family: Poopins-Semibold;
                font-size: 40px;
                text-align: left;
                margin-top: 20px;
                margin-bottom: 10px;
            }

            & img {
                width: 100px;
            }
        }

        & .reachCustomersItem:nth-child(1),
        .reachCustomersItem:nth-child(4) {
            background-color: var(--purple-dark-bg);
        }

        & .reachCustomersItem:nth-child(2),
        .reachCustomersItem:nth-child(3) {
            background-color: var(--dark-pink);
        }
    }
}

.boostingPostHeading {
    // padding: var(--pd-200);
    padding: 50px 80px;
    border-radius: 20px;
    background-color: '#181818';
    color: var(--black-text);
    // margin-bottom: 100px;
    margin-top: 100px !important;

    & h1,
    p {

        text-align: left;
        margin-bottom: 30px;
        font-family: Poopins-Regular;
    }

    & p {
        font-family: Poopins-Regular;
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
    }
}

.leadingCouriers {
    text-align: center;
    margin-top: 100px !important;
    margin-bottom: 70px !important;

    & .boostingPostSubheading {
        color: var(--white-text);
        border: 1px solid var(--white-bg);
        border-radius: 20px;
        padding: var(--pd-300);
        width: 1020px;
        max-width: 100%;
        border-radius: 12px !important;
        background: linear-gradient(#1C1C1C, #1C1C1C) padding-box,
            linear-gradient(to right, #7123d6, #f26159) border-box;
        // border-radius: 50em;
        border: 2px solid transparent;
        // border: 1px solid;
        // border-image: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
        // border-image-slice: 1;

        & h1 {
            margin-top: 0px;
            margin-bottom: 20px;
        }

        & p {
            text-align: center !important;
            max-width: 100%;
            margin-bottom: 10px;
        }

        // & button {
        //     margin-top: 20px;
        //     border-radius: 5px;
        //     font-family: Poopins-Regular;
        //     font-size: 20px;
        //     padding: 10px 15px 10px 15px;
        //     border: none;
        //     max-width: fit-content;
        // }
    }

}

.getStartedBtn {
    padding: 10px 20px 10px 20px;
    font-family: Poopins-SemiBold;
    font-size: 20px;
    // font-weight: 700;
    line-height: 24px;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    width: fit-content;
    max-width: 100%;

}

@media screen and (max-width:769px) {

    .contentImgContainer {
        // & h1,p{
        //     text-align: center !important;
        // }

    }

    .contentImgContainer button {
        width: 100%;
    }

    h1 {
        font-size: 34px !important;
        line-height: normal !important;
    }

    p {
        font-size: 18px !important;
        line-height: normal !important;
    }

    h2 {
        font-size: 28px !important;
        line-height: normal !important;
    }

    .boostingPostHeading {
        padding: 25px !important;
    }

}



@media screen and (max-width:769px) {
    .heading {
        padding: 25px !important;
        border-radius: 30px;
        margin-bottom: 30px;

        & p {
            margin-top: 10px;
        }
    }

    .marketplaceAdvertising {

        // align-items: center !important;
        & div {


            & p {
                margin-top: 10px !important;
            }
        }

        & img {
            width: 80px !important;
            height: 80px !important;
            // position: relative !important;
            // bottom: 10px !important;
            // top: 5px !important;
            // right: 30px;
            object-fit: contain;
            display: none;
            // border: 2px solid red;
            // left: 20px !important;
            // align-self: flex-end;
            // justify-self: flex-end;
        }
    }
}

.planet24 {
    position: absolute;
    right: 5%;
    top: 5%
}

.planet25 {
    position: absolute;
    left: 5%;
    top: 15%;
}

.planet26 {
    position: absolute;
    right: 5%;
    top: 19%;
}

.planet27 {
    position: absolute;
    left: 5%;
    top: 30%;
}

.planet28 {
    position: absolute;
    left: 5%;
    top: 65%;
}

.planet29 {
    position: absolute;
    right: 5%;
    top: 78%;
}

.planet30 {
    position: absolute;
    right: 5%;
    top: 40%;
}

.planet31 {
    position: absolute;
    left: -5%;
    top: 87%;
}

.planet32 {
    position: absolute;
    right: 5%;
    top: 93%;
}

@media screen and(max-width:769px) {
    .planet24 {
        position: absolute;
        right: 3%;
        top: 4%;
        z-index: -20;
        width: 20%;
        display: none;
    }

    .planet25 {
        position: absolute;
        left: 0%;
        top: 13%;
        z-index: -20;
        display: none;
    }

    .planet26 {
        position: absolute;
        right: 0%;
        top: 17%;
        z-index: -20;
        width: 30%;
        display: none;
    }

    .planet27 {
        position: absolute;
        left: 0%;
        width: 40%;
        top: 30%;
        z-index: -20;
        display: none;
    }

    .planet28 {
        position: absolute;
        left: 0%;
        top: 52%;
        // z-index: -20;
        width: 50%;
        display: none;
    }

    .planet29 {
        position: absolute;
        right: 0%;
        top: 83%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet30 {
        position: absolute;
        right: 5%;
        top: 39%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet31 {
        position: absolute;
        left: -5%;
        top: 93%;
        width: 50%;
        z-index: -20;
        display: none;
    }

    .planet32 {
        position: absolute;
        right: 5%;
        top: 99%;
        width: 40%;
        z-index: -20;
        display: none;
    }

    .reachCustomersItem:nth-child(3) {
        order: 4;
    }

    .courierItems:nth-child(4) {
        order: 5;
    }
}

@media screen and (min-width:992px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:992px) {}

@media screen and (max-width:600px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:996px) {}


@media screen and (min-width:1400px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}


@media screen and (min-width:996px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}