h1,
p {
    text-wrap: wrap;
    word-wrap: break-word;
    max-width: 100%;
}

.mainContainer {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;


    & canvas {
        width: 100vw;
        height: 110vh;
    }

    & .content {
        top: 0px;
        left: 0px;
        position: absolute;
        width: 100vw;
    }
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & .downArrow {
        width: 50px;
        height: 100px;
        position: relative;

        & img {
            position: absolute;
            width: 100%;
        }
    }
}

.contentImgContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    font-size: 100px;
    line-height: 121.9px;
    color: #FFFFFF;
    gap: 20px;
}

.contentImgContainer h1 {
    font-size: 100px;
    font-family: Poopins-Bold;
    font-weight: 700;
    line-height: 122px;
    letter-spacing: 0em;
    text-align: left;
}

.contentImgContainer p {
    padding-left: 10px;
    font-size: 20px;
    font-family: "Poopins-Regular";
    line-height: 29.26px !important;
}

@media screen and (max-width:769px) {
    .contentImgContainer h1 {
        text-align: center !important;
    }

    .contentImgContainer p {
        text-align: center !important;
    }
}

.retailIsHere {
    background: linear-gradient(183.98deg, #FE655D 12.15%, #8226F7 96.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content {
    width: 100%;
    min-height: 110vh;
    display: flex;
    justify-content: flex-start;
}

.button {
    // background-color: #FFFFFF;
    border-radius: 20px;
    text-decoration: none;
    // background-color: #FFFFFF;
    border-radius: 20px;
    text-decoration: none;
    padding: 40px;
    text-align: center;
    font-size: 40px;
    min-width: 50vw;
    line-height: 48.76px;
    font-family: Poopins-Bold;
    color: #FFFFFF;
}

.button span {
    color: grey;
}

.transformYourBusinessContainer {
    position: relative;
    text-align: center;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    & .noBsSection {
        color: var(--black-text);
        text-align: left;
        width: 1100px !important;
        max-width: 100%;
        margin-bottom: 70px;
        // gap: 40px;

        & .justStraightTalk {
            color: #8226F7;
        }

        & p {
            text-align: left;
            font-size: 16px;
            line-height: 16.94px;
            margin-top: 20px;
        }
    }

    & .heading {
        padding: 50px;
        border-radius: 30px;
        width: 1270px;
        max-width: 100%;
        margin-top: 120px;
        margin-bottom: 20px;
        // border: 2px solid red;
    }


    & h1 {
        color: #222222;
        font-family: Poopins-Bold;
        font-size: 60px;
    }

    & .grayColorHeading {
        color: #A1A1A1;
        margin-bottom: 30px;
    }

    & p {
        font-family: Poopins-Regular;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 5px;
    }

    & button {
        padding: 10px 20px 10px 20px;
        font-family: Poopins-Bold;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        background-color: #222222;
        color: #FFFFFF;
        border-radius: 5px;
        border: none;
        margin-top: 20px;
    }

    & .noBs {
        margin-top: 50px;
        margin-bottom: 50px;
        /* background-color: #E6D4FD; */
        color: #111111;
        text-align: left;
        /* max-width: 70vw; */
        padding: 50px;

        & .justStraightTalk {
            color: #8226F7;
        }

        & p {
            text-align: left;
            font-size: 18px;
            line-height: 21.94px;
        }
    }

    & .sellWhever {
        margin-top: 50px;
        margin-bottom: 50px;
        background-color: #1C1C1C;
        color: #111111;
        text-align: left;
        padding: 50px;
        margin-top: 120px;
        margin-bottom: 40px;
        width: 1100px;
        max-width: 100%;
        padding-left: 50px;


        & .justStraightTalk {
            color: #FF725E;
        }
    }
}

.noBsContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .noBsItems {

        & .noBsItem1 {
            background-color: #222222;
            color: #FFFFFF;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-radius: 20px;
            padding: 60px;
            gap: 40px;
            margin-bottom: 20px;
            width: 1100px;
            // border: 2px solid green;

            & div {
                & h2 {
                    font-family: Poopins-Bold;
                    font-size: 40px;
                    text-align: left;
                }

                & p {
                    text-align: left;
                    font-family: Poopins-Regular;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;

                }

                & img {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 10px
                }
            }

            & .knowMore {
                text-align: left;
                margin-top: 20px;
                display: flex;
                font-size: 24px;
                font-family: Poopins-Regular;
                gap: 8px;

                & img {
                    width: 24px;
                    height: 24px;
                    margin-top: 5px;
                }
            }

            & img {
                max-width: 100%;
            }
        }

        & .noBsItem2 {
            background-color: #8226F7;
            color: #FFFFFF;
            border-radius: 20px;
            padding: 40px;
            width: 500px;

            & h2 {
                font-family: Poopins-Bold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 20px;
            }

            & p {
                text-align: left;
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;

            }

            & .knowMore {
                text-align: left;
                margin-top: 20px;
                display: flex;
                font-size: 24px;
                gap: 8px;
                font-family: Poopins-Regular;

                & img {
                    width: 24px;
                    height: 24px;
                    margin-top: 5px;
                }
            }

            & img {
                // margin-bottom: 30px;
                max-width: 70%;
            }
        }
    }

    & div {
        & .brandPartners {
            margin: auto;
            border-radius: 30px;
            margin-top: 20px;
            padding: 50px;
            display: flex;
            flex-direction: row;
            background-color: #181818;
            justify-content: center;
            width: 1020px;
            max-width: 100%;

            & span {
                display: flex;
                flex-direction: column-reverse;
                width: 33%;
                text-align: left;
                margin-bottom: 20px;

                & h1 {
                    font-size: 50px;
                    font-family: Poopins-Bold;

                }


                & img {
                    width: 70px;
                    object-fit: contain;
                    max-width: 100%;
                }
            }


            & .brandPartnersGrid {
                width: 30%;

                // border: 2px solid yellow;

                & img {
                    width: 70% !important;
                    // border: 2px solid red;
                    object-fit: contain;
                    max-width: 100%;
                }

            }
        }
    }
}

.sellWhereverContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .sellWhereverItems {

        & .sellWhereverItem1 {
            background-color: #181818;
            color: #222222;
            flex-direction: row;
            border-radius: 20px;
            padding: 60px;
            gap: 40px;
            width: 1020px;
            margin-top: 20px;
            max-width: 100%;

            & h2 {
                font-family: Poopins-Bold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 50px;
                text-align: center;

                & img {
                    width: 40px;
                    // border: 2px solid red;
                }
            }

            & .ourPatronsContainer {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;
                // border: 2px solid red;

                & .carouselContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center !important;
                    // border: 2px solid green;
                }

                & img {
                    width: 90% !important;
                    max-width: 100%;
                    object-fit: contain;
                    border: 1px solid transparent;
                    align-self: center;
                }
            }

            // & div {
            //     // display: grid;
            //     // grid-template-columns: auto auto auto auto;
            //     // gap: 20px;

            //     & img {
            //         width: 150px;
            //         max-width: 100%;
            //     }
            // }
        }

        & .sellWhereverItem2 {
            background-color: #FE746D;
            color: #FFFFFF;
            border-radius: 20px;
            padding: 40px;
            width: 500px;
            max-width: 100%;

            & h2 {
                font-family: Poopins-Bold;
                font-size: 40px;
                text-align: left;
                margin-bottom: 20px;

            }

            & p {
                text-align: left;
                font-family: Poopins-Regular;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 10px;
            }

            & .knowMore {
                text-align: left;
                margin-top: 20px;
                display: flex;
                font-size: 24px;
                gap: 8px;
                font-family: Poopins-Regular;

                & img {
                    width: 24px;
                    height: 24px;
                    margin-top: 5px;
                }
            }

            & img {
                max-width: 100%;
            }
        }
    }

}

.sellWhever {
    border-radius: 30px;
    color: #222222;
    padding: 50px;
    background-color:"#1C1C1C";
    margin-top: 70px;
    // border: 2px solid red;

    & h2 {
        text-align: left;
        font-family: Poopins-Bold;
        font-size: 40px;
    }

    & .yourCustomersAre {
        color: #FF725E;
    }
}

.howWeGrow {
    margin: auto;
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    margin-bottom: 20px;
    text-align: left;
    flex-wrap: wrap;
    gap: 40px;
    width: 1040px;
    margin-top: 120px;
    margin-bottom: 20px;
    background-color: #0d0d0d;
    color: #FFFFFF;
    padding: 50px;
    max-width: 100%;

    & h1 {
        color: #FFFFFF;
    }

    & .brand {
        color: #FF725E;
    }

    & div {
        width: 450px;
        max-width: 100%;

        & .brandDescription {
            text-align: left;
            font-family: Poopins-Regular;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
        }

        & p {
            position: relative;
            display: inline-block;
            font-family: Poopins-Bold;
            font-size: 25px;
            text-align: left;
            color: #FFFFFF;
            margin-top: 35px;

            & img {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(-100%);
                transition: transform 1s ease-in-out, opacity 1s ease-in-out;
                z-index: 1;
            }
        }

        & p:hover img {
            transform: translateX(0);
            display: block;
        }

        & p:hover {
            color: var(--pink-text);
        }
    }
}

.sliderContainer {
    max-width: 1020px;
    margin: auto;
}

.testimonialContainer {
    display: flex;
    flex-direction: row;
    width: 1000px;
    margin: auto;
    gap: 20px;
    max-width: calc(100vw - 50px);
    flex-wrap: wrap;
    background-color: #181818;
    color: #FFFFFF;
    border-radius: 30px;
    padding: 30px;

    & div {
        width: 450px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        & img {
            width: 200px;
            max-width: 100%;
        }

        & p {
            text-align: center !important;
            font-size: 18px;
        }

        & span {
            width: 100%;
            margin-top: 20px;
            margin-bottom: -10px;
            font-family: Poopins-Bold;
            font-size: 20px;
            text-align: center;
            margin: 10px 0px;
        }

        & div {
            justify-content: flex-start;

            & button {
                padding: 5px;
                border-radius: 8px;
                border: 1px solid #FFFFFF;
                background-color: transparent;
                color: #FFFFFF;
                font-size: 14px;
                font-family: Poopins-Bold;
            }

            & button:hover {
                background-color: #A1A1A1;
            }
        }
    }
}

.growthFormula {
    display: flex;
    gap: 20px;
    text-align: left;
    margin-top: 100px;
    flex-wrap: wrap;
    width: 1040px;
    margin: auto;
    margin-top: 120px;
    max-width: 100%;
    // margin-bottom: 50px;

    & div {
        width: 500px;
        max-width: 100%;
        // margin-bottom: 40px;

        h1 {
            font-family: Poopins-Bold;
            font-size: 55px;
            text-align: left;
            color: var(--white-text);
        }

        p {
            font-family: Poopins-Regular;
            font-size: 24px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--white-text);
        }

        & form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            // margin-bottom: 50px;

            & input {
                width: 100%;
                height: 64px;
                margin-top: 20px;
                padding: 20px;
                border-radius: 4px;
                font-family: Poopins-Medium;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                border: none;
                max-width: 100%;
            }

            & input:focus {
                border-image-source: repeating-linear-gradient(to right, #7123d6, #f26159);
                border-image-slice: 1;
                /* Use 1 to slice the gradient evenly */
                border-image-width: 2px;
                /* Set the width of the border */
                border-image-outset: 0;
                /* Set outset to 0 */
                border-image-repeat: round;
                /* Repeat the gradient */
                border-color: transparent;
            }

            & button {
                width: 100%;
                height: 56px;
                border-radius: 4px;
                color: #FFFFFF;
                background: linear-gradient(90.16deg, #FF725E -0.21%, #8226F7 100.94%);
                cursor: pointer;
            }
        }
    }

    & img {
        width: 50%;
        max-width: 100%;
    }
}

.useHyperspace {
    display: flex;
    gap: 20px;
    width: 1040px;
    margin: auto;
    position: relative;
    flex-wrap: wrap;

    & div {
        margin: 0px;
        background-color: #FFFFFF;
        padding: 50px;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & h1 {
            font-family: Poopins-Bold;
            font-size: 60px;
            text-align: left;
            margin-bottom: 0px;
        }

        & img {
            position: relative;
            // bottom: 50px;
            // left: 50px;
            width: 66px;

            animation: zoomer 1s ease-in infinite;
            margin-top: 10px;
           
            // border: 2px solid red;
        }

        @keyframes zoomer {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.05);
            }

            100% {
                transform: scale(1);
            }
        }
    }

    & img {
        width: 100%;
    }
}

.questionsPerk {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 180px;
    margin-top: 150px;

    & img {
        width: 150px;
        max-width: 100%;
    }
}

.column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
}

image {
    max-width: 100%;
}

@media screen and (max-width:769px) {
    .howWeGrow {
        padding: 40px !important;

        & div {
            & p {
                margin-top: 25px;
            }
        }
    }

    .useHyperspace {
        & div {
            padding: 25px;
        }
    }

    .transformYourBusinessContainer {
        padding-top: 0px !important;
        // border: 2px solid green;

        & .heading {
            padding: 20px !important;
            // border-radius: 30px;
            // width: 1270px;
            // max-width: 100%;
            margin-top: 20px !important;
            margin-bottom: 10px !important;
            text-align: left !important;
            // border: 2px solid red;

            & button {
                width: 100%;
            }
        }
    }

    .transformYourBusinessContainer,
    .content {
        padding-left: 10px;
        padding-right: 10px;

    }

    .questionsPerk {
        margin-left: 20px;
        margin-right: 20px;
        gap: 20px;
        margin-top: 100px;
        margin-bottom: 100px;

        & img {
            max-width: calc(50% - 60px);
        }
    }

    .brandPartners {
        padding: 5px !important;
        gap: 30px !important;
        // justify-content: space-between !important;
        // border: 2px solid red;

        & span {
            width: 45% !important;
            // border: 2px solid green;
            & h1 {
                font-size: 26px !important;
            }

            & img {
                width: 40px !important;
            }
        }

        & .brandPartnersGrid2 {
            display: none !important;
        }

        & .brandPartnersGrid {
            & img {
                width: 100% !important;
                object-fit: contain;
            }
        }
    }

    .growthFormula {

        & div,
        img {
            width: 100%;
            object-fit: contain;
            margin-top: 20px !important;
        }
    }

}

.button {
    padding: 20px;
    text-align: center;
    font-size: 34px;
    min-width: 50vw;
    line-height: 48.76px;
    font-family: Poopins-Bold;
    color: #FFFFFF;
}

.ourPatronsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    // border: 2px solid red;

    & .carouselContainer {
        display: flex;
        justify-content: center;
        align-items: center !important;
        // border: 2px solid green;
    }

    & .carouselContainer2 {
        display: none !important;
    }

    & img {
        width: 100% !important;
        object-fit: cover;
        // border: 2px solid white;
        align-self: center;
    }
}

.sellWhereverItem1 {

    padding: 40px !important;
    gap: 20px !important;

    // margin-bottom: 10px !important;


    & h2 {

        margin-bottom: 20px !important;
    }
}

.noBsItem1 {
    padding: 40px !important;
    // border: 2px solid red !important;
}

.testimonialContainer {
    padding: 40px !important;

    & div {
        & img {
            width: 150px;
        }
    }
}



@media screen and (min-width:769px) {

    .noBsItem1,
    .howWeGrow {
        & div {
            max-width: 50%;
        }
    }

    .useHyperspace {


        & img,
        div {
            max-width: calc(50% - 10px);
        }
    }
}

@keyframes zoomAnimation {

    0%,
    100% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.5);
    }
}

@keyframes upDownAnimation {

    0%,
    100% {
        top: 10%;
    }

    50% {
        top: 15%;
    }
}

@keyframes rotateAnimation {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.animateUpDown {
    position: absolute;
    animation: upDownAnimation 6s ease-in-out infinite;
}

.amimateRotate {
    position: absolute;
    animation: rotateAnimation 18s ease-in-out infinite;
}

.animateZoom {
    position: absolute;
    animation: zoomAnimation 10s ease-in-out infinite;
}


.planet1 {
    position: absolute;
    right: -12%;
    top: 5%;
}

.planet2 {
    position: absolute;
    left: -12%;
    top: 10%
}

.planet3 {
    position: absolute;
    left: -12%;
    top: 18%;
}

.planet4 {
    position: absolute;
    left: -12%;
    top: 37%;
}

.planet5 {
    position: absolute;
    right: -20%;
    top: 30%;
}

.planet6 {
    position: absolute;
    left: -12%;
    top: 47%;
    width: 20%;
}

.planet7 {
    position: absolute;
    right: -12%;
    top: 58%;
}

.planet8 {
    position: absolute;
    left: -12%;
    top: 65%;
}

.planet9 {
    position: absolute;
    right: -12%;
    top: 75%;
}

.planet10 {
    position: absolute;
    left: -2%;
    top: 83%;
}

.planet11 {
    position: absolute;
    right: -8%;
    top: 87%
}

.planet12 {
    position: absolute;
    left: -14%;
    top: 93%;
}

.planet13 {
    position: absolute;
    right: -13%;
    bottom: -1%;
}

@media screen and (max-width:769px) {
    .planet1 {
        position: absolute;
        right: -12%;
        top: 4%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet2 {
        position: absolute;
        left: -12%;
        top: 10%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet3 {
        position: absolute;
        left: -12%;
        top: 20%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet4 {
        position: absolute;
        left: -12%;
        top: 35%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet5 {
        position: absolute;
        right: -20%;
        top: 32%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet6 {
        position: absolute;
        left: -12%;
        top: 50%;
        width: 20%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet7 {
        position: absolute;
        right: -12%;
        top: 57%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet8 {
        position: absolute;
        left: -12%;
        top: 66%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet9 {
        position: absolute;
        right: -12%;
        top: 75%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet10 {
        position: absolute;
        left: -2%;
        top: 81%;
        z-index: -20;
        width: 30%;
        display: none;
    }

    .planet11 {
        position: absolute;
        right: -8%;
        top: 87%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet12 {
        position: absolute;
        left: -14%;
        top: 95%;
        z-index: -20;
        width: 50%;
        display: none;
    }

    .planet13 {
        position: absolute;
        right: -10%;
        bottom: 0%;
        z-index: -20;
        width: 50%;
        display: none;
    }
}

.success {
    color: green;
    font-family: "Poopins-Regular";
}

@media screen and (min-width:992px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:992px) {}

@media screen and (max-width:600px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:996px) {}


@media screen and (min-width:1400px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}


@media screen and (min-width:996px) {}

@media screen and (min-width:769px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}

@media screen and (min-width:1400px) {}