@tailwind base;
@tailwind components;
@tailwind utilities;

/* Main headings:
 h1:60px,
 p:24-->18px,
 p:20--->16px,
 btn-text:16px,
 btn-paddding:6px 12px,
 gap:50-->30px,
 gap:60-->35,
 gap:30-->15px,
 gap:40:20px,
 padding-->80,100--->40,60px,
 margin-top--->200-->100px,
 padding:60-->40px,
 font18:16px;
 font50:35;
 font40:30;
  */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif,'League Spartan';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
